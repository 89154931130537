<template>
  <section class="login-content">
    <div class="container mt-8">
      <div class="row align-items-center justify-content-center h-100">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="auth-logo">
                <img :src="logo_kerinci" class="img-fluid rounded-normal" style="height: 100px;" alt="logo">
              </div>
              <h2 class="mb-2 text-center">Verifikasi SPT</h2>
              <form @submit.prevent="proses()">
                <div class="row justify-content-center">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Kode</label>
                      <input class="form-control" type="text" v-model="key">
                    </div>
                  </div>
                </div>
                
                <div class="d-flex justify-content-center align-items-end">
                  <button @click="kembali()" class="btn btn-info mr-2">Kembali</button>
                  <button type="submit" class="btn btn-primary float-right">Proses</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="vShow">
        <div class="col-lg-12">
          <div class="card card-block card-stretch card-height">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div class="table-responsive-sm">
                    <table class="table no-border">
                      <tbody>
                        <tr>
                          <td>No. SPT</td>
                          <td>{{ header.no_spt }}</td>
                          <td>Alat Transportasi</td>
                          <td>{{ header.transportasi }}</td>
                        </tr>
                        <tr>
                          <td>Tujuan</td>
                          <td>{{ header.daerah_tujuan }}</td>
                          <td>Tanggal Berangkat</td>
                          <td>{{ header.tgl_berangkat }}</td>
                        </tr>
                        <tr>
                          <td>Asal</td>
                          <td>{{ header.daerah_asal }}</td>
                          <td>Tanggal Kembali</td>
                          <td>{{ header.tgl_kembali }}</td>
                        </tr>
                        <tr>
                          <td>Status</td>
                          <td><strong>{{ header.status }}</strong></td>
                          <td>Perjalanan Dinas</td>
                          <td>{{ header.jenis_dinas }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="mx-2">
                    <strong>Daftar Pegawai</strong>
                    <ul class="mt-2" v-for="item in child" :key="item.nama_pegawai">
                      <li>{{ item.nama_pegawai }}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
// import { mapActions } from "vuex";
import { getDataService } from '../../store/modules/crudservices'
import { showToast } from '../../_utils/toast'

export default {
  name:'Verifikasi',
  components: {},
  data() {
    return {
      vShow: false,
      key: '',
      header:[],
      child:[],
      logo_kerinci: require('../../assets/images/logo_sppd.png'),
      showError: false
    };
  },
  methods: {
    kembali(){
      this.$router.push('/Login') 
    },
    proses(){
      const url = `/spt/verifikasi?key=${this.key}`
      getDataService(url).then(response => {
        showToast(response.data.type, response.data.message[0])
        this.header = response.data.header
        this.child = response.data.child

        this.vShow = response.data.type == 'error' ? false : true
      })
    }
  },
  mounted() {
    this.key = this.$route.query.key
    if(this.key) this.proses()
  }
}
</script>